import compose from '../../../../lib/compose';
import connect from '../../../../store/connect';
import * as actions from '../../../../actions/vip/vis';
import withFeatureFlag from '../../../../components/feature-flag/with-feature-flag';
import QuestionsUnregistered from '../../../../components/questions/questions-unregistered';

const FEATURE_NAME = 'unregistered_questions_modal';

const mapStateToProps = ({
  [FEATURE_NAME]: { id, form, messages, disabled, conditions, key, recaptchaPublicKey, isFetching, ...rest },
  baseUrl,
  id: itemId,
  recaptchaSiteKey,
}) => ({
  id,
  form,
  messages,
  disabled,
  url: `${baseUrl}/${itemId}/questions`,
  itemId,
  conditions,
  key,
  isFetching,
  recaptchaPublicKey,
  recaptchaSiteKey,
  isModal: true,
  ...rest,
});

const mapDispatchToProps = dispatch => ({
  onChange: ({ input, value }) => dispatch(actions.onChangeUnregisteredQuestionValue({ input, value })),
  onCreateQuestion: ({ itemId, form, outPutValues, track, hasErrors, recaptchaV2Token, recaptchaSiteKey }) =>
    dispatch(
      actions.onCreateQuestionsUnregistered({
        itemId,
        form,
        outPutValues,
        track,
        hasErrors,
        component: FEATURE_NAME,
        recaptchaV2Token,
        origin: 'modal',
        recaptchaSiteKey,
      }),
    ),
  onSetFormErrors: ({ form }) =>
    dispatch(actions.onSetQuestionsUnregisteredFormError({ form, component: FEATURE_NAME })),
  onEnableStorageData: ({ canEdit, isEditing }) =>
    dispatch(actions.onEnableStorageDataInQuestionsUnregisteredForm({ canEdit, isEditing, component: FEATURE_NAME })),
});

const enhance = compose(
  withFeatureFlag(FEATURE_NAME, { pathToComponent: FEATURE_NAME }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(QuestionsUnregistered);
export { mapDispatchToProps };
